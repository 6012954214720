import React, { useState } from 'react';
import { supabase } from '../../services/supabaseClient'; // Import Supabase client
import { useUser } from '../auth/UserContext';
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist/build/pdf';
import { v4 as uuidv4 } from 'uuid'; // Import the UUID function


GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist/build/pdf.worker.min.js`;

export default function DocumentAI() {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [message, setMessage] = useState('');
    const [csvData, setCsvData] = useState('');
    const { user } = useUser();

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        
        // Reset all states when a new file is selected
        setFile(null);
        setUploading(false);
        setIsAnalyzing(false);
        setMessage('');
        setCsvData('');

        if (selectedFile && selectedFile.type !== 'application/pdf') {
            setMessage('Please upload a PDF file.');
            return;
        }
        setFile(selectedFile);
    };

    const extractTextFromPDF = async (file) => {
        const pdfData = await getDocument(URL.createObjectURL(file)).promise;
        let textContent = '';
        for (let i = 1; i <= pdfData.numPages; i++) {
            const page = await pdfData.getPage(i);
            const content = await page.getTextContent();
            const pageText = content.items.map(item => item.str).join(' ');
            textContent += pageText + '\n';
        }
        return textContent;
    };

    const handleUpload = async () => {
        if (!user) {
            setMessage('You must be logged in to upload a document.');
            return;
        }

        if (!file) {
            setMessage('Please select a file to upload.');
            return;
        }

        setUploading(true);
        const tenantId = user.tenant_id;
        const fileName = uuidv4(); // Generates a UUID
        const fileExtension = file.name.split('.').pop(); 
        const filePath = `documents/${tenantId}/${fileName}.${fileExtension}`;


        const { data, error } = await supabase.storage
            .from('documents')
            .upload(filePath, file);

        if (error) {
            setMessage(`Error uploading file: ${error.message}`);
            setUploading(false);
            return;
        }

        const documentContent = await extractTextFromPDF(file);

        const { data: documentData, error: insertError } = await supabase
            .from('documents')
            .insert([{ 
                document_path: filePath, 
                document_name: 'Financial Statement', 
                created_at: new Date(), 
                document_content: documentContent,
                tenant_id: tenantId 
            }], { returning: 'representation' })
            .select();

        if (insertError) {
            setMessage(`Error inserting record: ${insertError.message}`);
            console.error(insertError);
        } else if (documentData && documentData.length > 0) {
            const documentId = documentData[0].id;
            setMessage(`File uploaded successfully, data is being extracted, please wait.`);

            // Start analyzing state
            setIsAnalyzing(true);

            // Set a timeout for analysis (20 seconds here)
            setTimeout(async () => {
                const { data: aiOutputData, error: fetchError } = await supabase
                    .from('documents')
                    .select('ai_output')
                    .eq('id', documentId)
                    .single();

                // End analyzing state
                setIsAnalyzing(false);

                if (fetchError) {
                    console.error(`Error fetching AI output: ${fetchError.message}`);
                    setMessage(`Error fetching AI output: ${fetchError.message}`);
                } else if (aiOutputData) {
                    setCsvData(aiOutputData.ai_output);
                } else {
                    setMessage('No AI output found for the uploaded document.');
                }
            }, 20000); // 20 seconds delay
        } else {
            setMessage('Insert successful but no data returned.');
        }

        setUploading(false); // Reset uploading state
    };
    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(csvData).then(() => {
            setMessage('CSV data copied to clipboard!');
        }).catch((err) => {
            console.error('Failed to copy: ', err);
            setMessage('Failed to copy CSV data.');
        });
    };

    return (
        <div className="p-4">
            <h1 className="text-xl font-bold mb-4">Upload Financial Statement for Data Extraction</h1>
            <input
                type="file"
                onChange={handleFileChange}
                className="mb-4"
            />
            <button
                onClick={handleUpload}
                disabled={uploading || isAnalyzing} // Disable button during uploading or analyzing
                className={`px-4 py-2 bg-blue-500 text-white rounded ${
                    uploading || isAnalyzing ? 'opacity-50' : ''
                }`}
            >
                {uploading ? 'Uploading...' : isAnalyzing ? 'Analyzing...' : 'Upload'}
            </button>
            {message && <p className="mt-4 text-red-500">{message}</p>}

            {csvData && (
                <div className="mt-4">
                    <h2 className="text-lg font-bold mb-2">CSV Output</h2>
                    <textarea
                        value={csvData}
                        readOnly
                        rows={10}
                        className="w-full border border-gray-300 rounded p-2"
                    />
                    <button
                        onClick={handleCopyToClipboard}
                        className="mt-2 px-4 py-2 bg-green-500 text-white rounded"
                    >
                        Copy to Clipboard
                    </button>
                </div>
            )}
        </div>
    );
}